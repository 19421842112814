<template>
  <v-row>
    <v-dialog v-model="HintApta" scrollable persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Petunjuk Permainan</h3>
          <v-spacer></v-spacer>
          <h4 v-if="!close">Silakan tunggu... {{ counting }}</h4>
          <v-btn text v-if="close" @click="$emit('false')"
            >tutup <v-icon color="red">mdi-close keluar</v-icon></v-btn
          >
        </v-toolbar>
        <div class="py-3 px-5 d-flex flex-column align-center">
          <p>
            Pada permainan ini Anda diharuskan menyelesaikan perhitungan
            matematika secara cepat. Tekan kolom <b>“MASUKKAN JAWABAN”</b> dan
            tuliskan jawaban, lalu klik tombol <b>“JAWAB”</b>. Waktu Anda
            terbatas.
          </p>
          <img src="../../../assets/img/AptaView.png" height="300px" alt="" />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AptaHint",
  props: ["HintApta"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
